import React from 'react'
import Button from './Button'

const ContactForm = () => {
  return (
    <form
      className="ng-contact-form"
      name="contact-page"
      method="POST"
      data-netlify="true"
      netlify
    >
      <div className="ng-contact-textInputContainer">
        <input type="hidden" name="form-name" value="contact-page" />
        <div className="ng-contact-input">
          <label>Full Name*</label>
          <input type="text" name="Name" required />
        </div>
        <div className="ng-contact-input">
          <label>Email*</label>
          <input type="email" name="Email" required />
        </div>
        <div className="ng-contact-input">
          <label>Company Name*</label>
          <input type="text" name="Company Name" required />
        </div>
        <div className="ng-contact-input">
          <label>Phone Number*</label>
          <input type="tel" name="Phone Number" />
        </div>

        {/* Commented out to explore a better solution */}
        {/* <div className="ng-contact-input">
          <label>Company Type*</label>
          <select name="Company Type">
            <option value="Startup-Early Stage">Startup-Early Stage</option>
            <option value="Startup-Late Stage">Startup-Late Stage</option>
            <option value=" Small to Mid Sized Business">
              Small to Mid Sized Business
            </option>
            <option value=" Enterprise">Enterprise</option>
            <option value=" Non-Profit">Non-Profit</option>
            <option value=" Other - Not Listed">Other-Not Listed</option>
          </select>
        </div> */}
      </div>

      <label className="ng-contact-servicesNeeded">Services Needed</label>

      <div className="ng-contact-checkBoxes">
        <div className="ng-contact-checkBox">
          <label for="Web Development">
            <input
              type="checkbox"
              placeholder="Web Development"
              name="Web Development"
            />
            Web Development
          </label>
        </div>
        <div className="ng-contact-checkBox">
          <label for="SEO">
            <input type="checkbox" placeholder="SEO" name="SEO" value="SEO" />
            SEO
          </label>
        </div>
        <div className="ng-contact-checkBox">
          <label for="Mobile App">
            <input type="checkbox" placeholder="Mobile App" name="Mobile App" />
            Mobile App
          </label>
        </div>
        <div className="ng-contact-checkBox">
          <label for="Shopify">
            <input type="checkbox" placeholder="Shopify" name="Shopify" />
            Shopify
          </label>
        </div>
        <div className="ng-contact-checkBox">
          <label for="Industrial Solution">
            <input
              type="checkbox"
              placeholder="Industrial Solution"
              name="Industrial Solution"
            />
            Industrial Solution
          </label>
        </div>
        <div className="ng-contact-checkBox">
          <label for="Machine Learning">
            <input
              type="checkbox"
              placeholder="Machine Learning"
              name="Machine Learning"
            />
            Machine Learning
          </label>
        </div>
        <div className="ng-contact-checkBox">
          <label for="UI/UX Design">
            <input
              type="checkbox"
              placeholder="UI/UX Design"
              name="UI/UX Design"
            />
            UI/UX Design
          </label>
        </div>
        <div className="ng-contact-checkBox">
          <label for="Enterprise Solution">
            <input
              type="checkbox"
              placeholder="Enterprise Solution"
              name="Enterprise Solution"
            />
            Enterprise Solution
          </label>
        </div>
        <div className="ng-contact-checkBox">
          <label for="WordPress">
            <input type="checkbox" placeholder="WordPress" name="WordPress" />
            WordPress
          </label>
        </div>
        <div className="ng-contact-checkBox">
          <label for="Code Consulting">
            <input
              type="checkbox"
              placeholder="Code Consulting"
              name="Code Consulting"
            />
            Code Consulting
          </label>
        </div>
      </div>

      <div className="ng-contact-textArea">
        <label>Description of Services Needed*</label>
        <textarea name="Description of Services Needed" required />
      </div>
      <button className="btn btn--dark" type="submit">
        Submit
      </button>
    </form>
  )
}

export default ContactForm
