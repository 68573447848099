import React from 'react'
import SubHero from '../components/SubHero'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ContactForm from '../components/ContactForm'
import { FaMapPin, FaEnvelope } from 'react-icons/fa'

const Contact = () => {
  return (
    <Layout>
      <Helmet
        title="Contact Us | NEXTGEN Code Company"
        meta={[
          {
            name: 'description',
            content: 'Contact form and information for NextGen Code Company',
          },
        ]}
      />
      <SubHero title="Contact" subtitle="" />
      <section className="container ng-contact-container">
        <section className="ng-contact-smallAbout">
          <div className="ng-contact-links">
            <span>
              <FaMapPin
                color="#E25140"
                size={20}
                className="ng-contact-linkIcon"
              />
              Dallas, TX 75201
            </span>
            <span>
              <FaMapPin
                color="#E25140"
                size={20}
                className="ng-contact-linkIcon"
              />
              Lubbock, TX 79409
            </span>
            <span>
              <FaMapPin
                color="#E25140"
                size={20}
                className="ng-contact-linkIcon"
              />
              Corpus Christi, TX 78401
            </span>
            <span>
              <FaMapPin
                color="#E25140"
                size={20}
                className="ng-contact-linkIcon"
              />
              Austin, TX 78749
            </span>

            <span>
              <a href="mailto:tango@nextgencode.io " target="_blank">
                <FaEnvelope
                  color="#E25140"
                  size={20}
                  className="ng-contact-linkIcon"
                />
              </a>
              tango@nextgencode.io
            </span>
          </div>
        </section>
        <section className="ng-contact-formContainer">
          <ContactForm />
        </section>
      </section>
    </Layout>
  )
}

export default Contact
